<template>
  <v-card v-if="!refillListFormatted.length && loading">
    <v-sheet
      :color="`grey`"
      class="pa-3"
    >
      <v-skeleton-loader
        class="mx-auto"
        type="text-field, table, data-table"
      >
      </v-skeleton-loader>
    </v-sheet>
  </v-card>
  <v-card v-else>
    <v-card-title class="font-weight-semibold text-uppercase">
      Liste des recharges rejetées
    </v-card-title>
    <v-divider></v-divider>
    <br>
    <div class="mx-auto">
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            offset-md="8"
            md="4"
          >
            <v-text-field
              v-model="search"
              :append-icon="icon.mdiMagnify"
              label="Rechercher"
              single-line
              hide-details
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <!-- Table -->
      <v-data-table
        id="list-refill"
        :headers="headers"
        :items="refillListFormatted"
        :search="search"
        :items-per-page="itemPerPage"
        sort-by="state"
        :sort-desc="true"
        :divider="true"
        class="table-kitchen-sink mx-auto"
      >
        <template #[`item.num`]="{item}">
          <div class="d-flex align-center p-5">
            <div class="d-flex flex-column ms-3 ">
              <span class="font-weight-semibold text-body-1 text--primary"> N° {{ item.num }} </span>
              <span class="custom-text--small">Envoyé le : {{ item.open_date }}</span>
              <span
                v-if="item.close_date"
                class="custom-text--small custom-red--text"
              >Fermé le : {{ item.close_date }}</span>

              <historique :data='item.accounting_transactions'>

              </historique>

            </div>
          </div>
        </template>

        <template #[`item.type_depot`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <span class="d-block text-truncate text--primary">Type: <span class="font-weight-semibold"> {{ item.type_depot === DEPOT ?'Versement':'Virement' }}</span></span>
              <span class="d-block font-weight-semibold text-truncate text--primary"> {{ item.moyen_paiement }} </span>
            </div>
          </div>
        </template>

        <template #[`item.account_debite`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <span class="d-block font-weight-semibold text-truncate text--primary">Débiteur: <span class="font-weight-semibold">{{ item.account_debite===null?'N/A':item.account_debite }}</span></span>
              <span class="d-block font-weight-semibold text-truncate text--secondary">Créditeur: <span class="font-weight-semibold">{{ item.account_credite===null?'N/A':item.account_credite }}</span></span>
            </div>
          </div>
        </template>

        <template #[`item.amount`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <span class="d-block text-truncate text--primary">Montant: <span class="font-weight-semibold success--text"> {{ withCurrency(item.amount) }}</span></span>
              <span class="d-block text-truncate text--primary">Reférence: <span class="font-weight-semibold">{{ item.reference }}</span></span>
              <span v-if="item.file_name !=='0'" class="d-block text-truncate text--primary">Reçu: <a
                @click='getFileLink(item)'
                class="text-decoration-none custom--info"
              >
                <v-icon class="custom--icon">{{ icon.mdiFileDocumentOutline }}</v-icon> {{ item.file_name }}</a>
              </span>
            </div>
          </div>
        </template>

        <template #[`item.state`]="{item}">
          <div class="d-flex align-center">
            <div class="d-flex flex-column ms-3">
              <v-chip
                v-if="item.state===1"
                class="ma-2 text-center pr-5 pl-5"
                color="#F1FFE8FF"
              >
                <b class="success--text">Traité</b>
              </v-chip>
              <v-chip
                v-else-if="item.state===0"
                class="ma-2 text-center"
                color="#FFF6ECFF"
              >
                <b class="custom-warning--text">En cours</b>
              </v-chip>
              <v-chip
                v-else
                class="ma-2 text-center"
                color="#FFECECFF"
              >
                <b class="error--text">Rejeté</b>
              </v-chip>
            </div>
          </div>
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>

<script>
import {
  ref, onBeforeMount, computed, onMounted, provide,
} from '@vue/composition-api'
import {
  mdiFileDocumentOutline,
  mdiMagnify,
} from '@mdi/js'
import Http from '@/helpers/http'
import moment from 'moment/moment'
import { REFILL_REPOSITORY_NAME } from '@/helpers/constants/refill'
import store from '@/store'
import Historique from '@/views/global/TransactionHistory'
import useDynamicConstant from '@/helpers/useDynamicConstant'

export default {
  components:{
    Historique,
  },
  setup() {
    provide('store', store)
    const DEPOT = 2
    const VIREMENT = 1
    const search = ref('')
    const listRefill = ref([])
    const refillList = ref([])
    const itemPerPage = 15
    const loading = ref(true)
    const contrat = JSON.parse(localStorage.getItem('defaultContrat'))

    const loadRefillList = () => {
      Http.get(`list-versement-rejected/${contrat.pivot.customer_segment_id}`)
        .then(response => {
          refillList.value = response.data
          loading.value = false
        })
        .catch(error => {
          console.log(error)
        })
    }

    const getFileLink = (item) => {
      const codeClient = (contrat.customer_type_code).split('-')

      store.dispatch('getUrlsOfFile', `dossiers-clients/${codeClient[0]}/${REFILL_REPOSITORY_NAME}/${item.file}`)
        .then(() => {
          // eslint-disable-next-line no-param-reassign
          item.file_url = store.getters.getFileUrl
          window.open(store.getters.getFileUrl, '_blank');
        })

    }

    const refillListFormatted = computed(() => {
      refillList.value.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.num = item.num_ticket,
        // eslint-disable-next-line no-param-reassign
        item.open_date = moment(item.created_at).format('DD-MM-YYYY H:mm:ss'),
        // eslint-disable-next-line no-param-reassign
        item.type_depot = (item.compteD.trim() === '') ? DEPOT : VIREMENT,
        // eslint-disable-next-line no-use-before-define
        item.moyen_paiement = nomMoyenPaiement(item.payment_method),
        // eslint-disable-next-line no-param-reassign
        item.account_debite = item.compteD.trim() === '' ? 'N/A' : item.compteD.trim(),
        // eslint-disable-next-line no-param-reassign
        item.account_credite = item.compteC.trim(),
        // eslint-disable-next-line no-param-reassign
        item.reference = item.ref.trim(),
        // eslint-disable-next-line no-param-reassign
        item.file_name = item.file,
        // eslint-disable-next-line no-param-reassign
        item.isChecked = item.cron_checked
      })

      return refillList.value
    })

    const meansOfPayment = ref([]) // pour le champ moyen de paiement

    const { CURRENCY } = useDynamicConstant()

    const currency = ref(CURRENCY)
    const withCurrency = price => `${Number(price).toLocaleString()} ${CURRENCY}`

    const loadMeansOfPayment = () => {
      Http.get('get-paymode')
        .then(response => {
          meansOfPayment.value = response.data
          console.log('meansOfPayment')
          console.log(response.data)

          // loadRefillList()
        })
        .catch(error => error)
    }

    // eslint-disable-next-line radix
    const nomMoyenPaiement = moyenCode => {
      const name = meansOfPayment.value.filter(m => m.code === moyenCode)
      if (name.length > 0) return name[0].label

      return ''
    }

    onBeforeMount(() => {
      loadRefillList()

      // loadMeansOfPayment()
    })

    return {
      icon: {
        mdiFileDocumentOutline,
        mdiMagnify,
      },
      search,
      itemPerPage,
      headers: [
        {
          text: 'Ticket', value: 'num', divider: true,
        },
        {
          text: 'Type de paiement', value: 'type_depot', divider: true,
        },
        {
          text: 'Compte', value: 'account_debite', divider: true,
        },
        {
          text: 'Paiement', value: 'amount', divider: true,
        },
        {
          text: 'État', value: 'state', divider: true,
        }, {
          text: 'Motif', value: 'comment', divider: true,
        },
      ],
      refillList,
      listRefill,
      DEPOT,
      VIREMENT,
      nomMoyenPaiement,
      loadMeansOfPayment,
      refillListFormatted,
      loading,
      withCurrency,
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      getFileLink,
    }
  },
  created() {
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/preset/preset/apps/refill.scss';

.custom--icon{
  height: 15px !important;
  width: 15px !important;
  color: #1E9FF2 !important;
}

.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
.custom-warning--text{
  color: darkorange;
  //background-color: #ffcfcf;
}

.custom-btn:hover{
  background-color: transparent !important;
}

.border{
  border: #0c5460 solid 1px;
}
</style>
